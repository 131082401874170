import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./service.css";
import "./specialmenu.css";

import TxtCourse from '../images/service/txt_course.png'
import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

const SpecialPage = () => (
  <Layout>
    <SEO title="お名前メソッド公認コーチ" />
    <div className="service specialmenu">
      <div className="fifth" id="service">
        <ul className="menu">
          <li>
            <div className="detail">
              <p className="ribbon">COURSE</p>
              <div className="head-box">
                <p className="txt-icon">
                  <img alt=" " src={TxtCourse} className="scale-to-fit" />
                </p>
                <p className="en">LESSON COURSE</p>
                <h2 className="special-title ff-min">
                  お名前メソッド
                  <span>&#174;</span><br />
                  公認コーチ講座
                </h2>
              </div>
              <div className="panel">
                <h4 className="course-title">「私らしさ」を自分で取り戻す事が出来る様にトレーニングで習慣づける「私らしさ」コーチング</h4>
                <ul>
                  <li>&#11045;お名前の知識を更に深め講座を開ける様になりたい</li>
                  <li>&#11045;おなまえメソッド®︎公認コーチとして人の役に立ちたい</li>
                  <li>&#11045;自分に惚れる人を増やしたい</li>
                </ul>
              </div>
              <div className="panel course">
                <div className="special-head">
                  <p>
                    <img alt=" " src={Motif_left} className="scale-to-fit" />
                  </p>
                  <h3 className="ff-min">講座内容</h3>
                  <p>
                    <img alt=" " src={Motif_right} className="scale-to-fit" />
                  </p>
                </div>
                <ul>
                  <li>&#11045;オンライン受講</li>
                  <li>1日集中コースまたは、3日間ゆったりコースをお選び頂けます。</li>
                  <li>＜所要時間＞全4時間30分</li>
                  <li>＜講座費＞78,000円(税抜) / 公認講師</li>
                </ul>
                <p className="read">
                  おなまえ<span>&#174;</span>トレーニングで身に付けた思考のトレーニングや、セルフケアの知識を心理学、脳科学の側面からより深めていきます。
                </p>
                <ul className="detail">
                  <li>名前で深層心理を読み説くとは?</li>
                  <li>名前を3カテゴライズに分類する手順</li>
                  <li>時間軸ごとの自分を知る方法</li>
                  <li>日常で活用する術を知りプライベートビジネスを充実させる仕組み</li>
                  <li>理想のエネルギーを生み出す仕組み</li>
                  <li>おなまえ<span>&#174;</span>トレーニング（入門、分析）開催方法の伝授</li>
                </ul>
                <p className="read">
                  「私らしさ」を自分で取り戻せると日常も未来にも変化が起きます。おなまえメソッド<span>&#174;</span>公認コーチとして一緒に自分に惚れる人を増やしましょう！
                </p>
                <div className="btn-holder ff-min">
                  <Link to='/contact' className="special-link">講師を選択する</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default SpecialPage
